<template>
  <router-link to="/" class="font-semibold text-2xl md:text-3xl font-display hover:underline flex items-end">
    <img src="/logo-color.png" alt="Labeltime" class="inline-block w-10 md:w-12 mr-2">Labeltime
  </router-link>
</template>

<script>
export default {
  name: 'Logo',
}
</script>