import Vue from 'vue'
import './index.css'
import App from './App.vue'
import contenteditable from 'vue-contenteditable'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"
import router from './router'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

Vue.use(contenteditable)
Vue.component("v-select", vSelect)
Vue.use(Toast, {
  icon: false,
  position: 'bottom-right',
  timeout: 2500,
})
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
