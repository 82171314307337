<template>
  <div class="fixed bg-black bg-opacity-30 top-0 left-0 w-full h-full z-50">
    <div class="bg-white px-8 py-8 rounded w-1/2 max-w-full min-w- absolute left-1/2 transform -translate-x-1/2 top-24 text-left">
      <slot></slot>
    </div>
  </div> 
</template>

<script>
export default {
  name: 'Modal',
}
</script>

