<template class="bg-gray-100 min-h-screen">
  <div class="bg-gray-100 p-8 text-center print:p-0">
    <div class="flex flex-col md:flex-row justify-between items-center header-section mx-auto mb-4 relative">
      <div class="inline-block text-left">
        <Logo />
      </div>
      <p class="block md:hidden bg-gray-200 px-3 py-2 mx-auto header-section rounded mt-4 text-xs font-semibold text-gray-700">
        Note: Editing experience is designed for laptop or desktop screens and may be difficult on phones or tablets.
      </p>
      <div class="mt-4 md:mt-0">
        <vButton :disabled="isLoading" @click="redirectHome()" color="blue" emoji="⬅️" class="ml-2">
            New
        </vButton>
        <vButton :disabled="isLoading" @click="saveLabelSheet" color="blue" emoji="💾" class="ml-2">
            Save
        </vButton>
        <vButton :disabled="isLoading" @click="showEmailModal = true" :primary="true" color="green" emoji="🖨" class="ml-2">
            Download
        </vButton>
      </div>
    </div>
    <LabelSheet v-if="addresses.length" 
                :labelFormatID="labelFormat"
                :addresses="addresses"
                :labelStyles="labelStyles"
                @updateLabelFormat="updateLabelFormat"
                @updateLabelStyles="updateLabelStyles"
                @updateAddresses="updateAddresses"></LabelSheet>
    <Modal v-if="showEmailModal">
      <h1 class="text-xl font-semibold mb-2">Download your PDF</h1>
      <p class="text-gray-600">Ready to print your labels? Enter your email to receive your print-ready PDF file!</p>
      <div v-if="emailSuccess" class="mt-4 py-4 px-6 bg-green-500 bg-opacity-10 border border-green-500 text-green-800 rounded">
        <h2 class="font-semibold">Labels on the way!</h2>
        <p>We've sent you an email at <span v-if="userEmail" class="font-bold mb-4">{{ userEmail }}</span> with a direct link to your printable PDF file.</p>
        <vButton :disabled="isLoading" @click="resetModal" color="blue" class="inline-block mt-3">
          Continue editing
        </vButton>
      </div>
      <form v-else class="mt-4">
        <label class="text-sm font-semibold text-gray-500" for="user-email">Email address</label>
        <input class="border border-gray-300 py-2 px-3 w-full text-left" 
              type="email"
              name="user-email"
              v-model="userEmail">
        <div class="mt-4">
          <vButton :disabled="isLoading" @click="saveAndSendLink()" :primary="true" color="green" class="mr-2">
            Send PDF
          </vButton>
          <vButton :disabled="isLoading" @click="showEmailModal = false" color="red">
            Cancel
          </vButton>
        </div>
      </form>
      <div v-if="saveStatus" class="mt-4 py-4 px-6 bg-gray-100 text-gray-500 rounded">
        <span class="animate-bounce inline-block w-2 h-2 bg-gray-400 rounded-full mr-3"></span>
        <span>{{ saveStatus }}</span>
      </div>
    </Modal>
  </div>
</template>

<script>
import vButton from "@/components/vButton.vue";
import LabelSheet from "@/components/LabelSheet.vue";
import Logo from "@/components/Logo.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: 'Editor',
  components: {
    vButton,
    LabelSheet,
    Logo,
    Modal
  },
  data () {
    return {
      addresses: [],
      output: null,
      hasSavedOnPress: false,
      userEmail: null,
      showEmailModal: false,
      emailSuccess: false,
      isLoading: false,
      saveStatus: null,
      labelFormat: null,
      labelStyles: {}
    }
  },
  methods: {
    resetModal() {
      this.emailSuccess = false
      this.isLoading = false
      this.showEmailModal = false
      this.saveStatus = null
    },
    redirectHome() {
      this.$router.push({ path: '/new' })
    },
    async saveAndSendLink() {
      window.fathom.trackGoal('FIHKJO98', 0);
      this.emailSuccess = false;
      this.isLoading = true;
      this.saveStatus = "LabelBot is generating your PDF..."
      const labelSheet = await this.saveLabelSheet(true)
      if (!labelSheet[0] || !labelSheet[0].pdfFile) {
        this.isLoading = false;
        this.saveStatus = null;
        return this.$toast('Error! Please try again.', { type: 'error' })
      } 

      this.saveStatus = "Preparing for email delivery..."

      const filename = labelSheet[0].pdfFile
      const uuid = labelSheet[0].uuid

      return await this.sendEmailLink(uuid, filename).then(() => {
        this.isLoading = false;
        this.emailSuccess = true;
        this.saveStatus = null;
        return this.$toast("Check your email for a link to your PDF file!", {type: 'success'})
      }).catch(err => {
        this.saveStatus = null;
        return this.$toast(err, {type: 'error'})
      })
    },
    async saveLabelSheet (ignoreLoading) {
      window.fathom.trackGoal('VNZM81VJ', 0);
      this.isLoading = true;
      const addresses = this.addresses || [];
      const user_email = this.userEmail || null;
      const uuid = this.$route.params.id || null;
      const label_format = this.labelFormat || null;
      const label_styles = this.labelStyles || null;

      let update = { addresses };
      if (user_email) update["user_email"] = user_email;
      if (label_format) update["label_format"] = label_format;
      if (label_styles) update["label_styles"] = label_styles;
      
      if (uuid) {
        const body = {
          from: 'label_sheets',
          uuid,
          update
        }
        const options = {
          method: "PATCH",
          headers: { 
              "Content-Type": "application/json; charset=utf-8" 
          },
          body: JSON.stringify(body)
        };

        let { data, error } = await fetch('/.netlify/functions/update-labelsheet', options).then(res => res.json())
          .catch(() => {
            this.isLoading = false;
            this.$toast('Error! Please try again.', {type: 'error'})
          })

        if (data) {
          if (ignoreLoading) return data
          this.isLoading = false;
          return this.$toast('Changes saved!', {type: 'success'});
        }
        if (error) {
          this.isLoading = false;
          return this.$toast(error.message, {type: 'error'});
        }
        this.isLoading = false;
        return this.$toast("Error! There was a problem saving your addresses.", {type: 'error'});
      }
    },
    async sendEmailLink(uuid, filename) {
      if (this.userEmail) {
        const email = this.userEmail

        const body = { email, uuid, filename }
        const options = {
          method: "POST",
          headers: { 
              "Content-Type": "application/json; charset=utf-8" 
          },
          body: JSON.stringify(body)
        };

        let { data, error } = await fetch("/.netlify/functions/send-email-link", options).then(res => res.json())
          .catch(() => {
            this.isLoading = false;
            this.saveStatus = null;
            this.$toast('Error! Please try again.', {type: 'error'})
          })
      
        if (data) return data;
        if (error) {
          this.isLoading = false;
          this.saveStatus = null;
          this.$toast(error, {type: 'error'})
        }
      }
    },
    saveKeyListener(e) {
      if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        if (!this.hasSavedOnPress) this.saveLabelSheet();
        this.hasSavedOnPress = true;
      }
    },
    resetHasSavedOnPress(e) {
      if (e.key !== "s") this.hasSavedOnPress = false;
    },
    async getLabelSheet () {
      const uuid = this.$route.params.id || null
      
      if (uuid) {
        const options = {
          method: "GET",
          headers: { 
              "Content-Type": "application/json; charset=utf-8" 
          }
        };
        const url = `/.netlify/functions/fetch-labelsheet?from=label_sheets&uuid=${uuid}`

        let { data, error } = await fetch(url, options).then(res => res.json())

        if(data && data.length) {
          this.addresses = data[0].addresses;
          if (data[0].user_email) this.userEmail = data[0].user_email
          if (data[0].label_format) this.labelFormat = data[0].label_format
          if (data[0].label_styles) this.labelStyles = data[0].label_styles
          return
        }

        if(error) this.$toast(error.message, { type: 'error' });
        return this.$toast("Hmmm... we're having trouble finding that label sheet", { type: 'error' });
      }

      return this.$toast("Hmmm... that label sheet doesn't seem to exist", {
        type: 'error',
      });
    },
    async downloadPDF () {
      const uuid = this.$route.params.id || null;
      const filename = `label-sheet-${uuid}.pdf`;

      if (uuid) {
        const options = {
          method: "GET",
          headers: { 
              "Content-Type": "application/json; charset=utf-8" 
          }
        };
        const url = `/.netlify/functions/pdf-file?filename=${filename}`

        let { data, error } = await fetch(url, options).then(res => res.json())

        if (data.signedURL) return window.open(data.signedURL, '_blank');
        if (error) return this.$toast(error.message, { type: 'error' });
      }
    },
    async generatePDF (uuid) {
      const body = { uuid }
      const options = {
        method: "POST",
        headers: { 
            "Content-Type": "application/json; charset=utf-8" 
        },
        body: JSON.stringify(body)
      }

      let { data, error } = await fetch("/.netlify/functions/generate-pdf", options).then(res => res.json())
      if (data) return data;
      if (error) return this.$toast(error, {type: 'error'})
    },
    updateAddresses(addresses) {
      this.addresses = addresses;
    },
    updateLabelFormat(format) {
      this.labelFormat = format;
    },
    updateLabelStyles(style, value) {
      this.labelStyles[style] = value;
    }
  },
  mounted() {
    this.$toast.clear();
    this.getLabelSheet();

    window.addEventListener('keyup', this.resetHasSavedOnPress);
    window.addEventListener('keydown', this.saveKeyListener);
  },
  beforeDestroy() {
    this.isLoading = false;
    this.$toast.clear();
    window.removeEventListener('keyup', this.resetHasSavedOnPress);
    window.removeEventListener('keydown', this.saveKeyListener);
  }
}
</script>

<style>
.paper {
  height: 11in;
  width: 8.5in;
  padding: 0.5in 0.25in;
}

.address {
  font-size: 11pt;
}

.label {
  font-size: 10pt;
  height: 1in;
  line-height: 1.35;
  max-height: 1in;
}
.label:first-child {
  border-top-width: 1px;
}
</style>
