<template class="bg-gray-200 min-h-screen">
  <div class="bg-gray-200 p-8 text-center print:p-0">
    <div class="print:hidden flex justify-between items-center header-section mx-auto mb-6 relative">
      <div class="inline-block text-left">
        <Logo />
      </div>
    </div>
    <div id="printArea">
      <LabelSheet v-if="labelSheet && labelSheet.addresses.length" 
                  :labelFormat="labelFormat.id"
                  :printStyles="true"
                  :labelStyles="labelStyles"
                  :addresses="labelSheet.addresses"></LabelSheet>
      <!-- <div  v-for="(page) in pages"
            :key="page.pageNumber"
            :style="pageStyleString"
            class="bg-white mb-8 drop-shadow mx-auto relative">
        <div class="print:hidden absolute top-2 -left-14 text-righttext-left">
          <label class="uppercase text-xs text-gray-400">Page {{ page.pageNumber }}</label>
        </div>
        <div  class="grid grid-cols-3 h-full content-center"
              :class="`grid-cols-${labelStyles.labelColumns}`"
              :style="`column-gap:${labelStyles.columnGutter}; row-gap:${labelStyles.rowGutter};`">
          <div 
            v-for="(address, index) in page.addresses"
            :key="`label-${index}`"
            :style="labelStyleString"
            class="text-center border border-gray-200 label content-border flex items-center content-center relative overflow-hidden">
            <div class="whitespace-pre-line w-full outline-none overflow-hidden z-10">
              {{ address }}
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import LabelSheet from "@/components/LabelSheet.vue";
import Logo from "@/components/Logo.vue";

export default {
  name: 'GeneratePDF',
  components: {
    LabelSheet,
    Logo
  },
  data () {
    return {
      labelSheet: null,
      labelFormatOptions: [{
        label: 'Avery 5160',
        id: 'avery-5160',
        labelsOnPage: 30,
        labelWidth: '2.625in',
        labelHeight: '1in',
        fontSize: '13px',
        lineHeight: '18px',
        fontWeight: 'regular',
        topMargin: '0.5in',
        bottomMargin: '0.5in',
        leftMargin: '0.21975in',
        rightMargin: '0.21975in',
        columnGutter: '0.14in',
        rowGutter: '0',
        pageWidth: '8.5in',
        pageHeight: '11in',
        labelColumns: 3,
      }, {
        label: 'Avery 5167',
        id: 'avery-5167',
        labelsOnPage: 80,
        labelWidth: '1.75in',
        labelHeight: '0.5in',
        fontSize: '12px',
        lineHeight: '14px',
        fontWeight: 'bold',
        topMargin: '0.5in',
        bottomMargin: '0.5in',
        leftMargin: '0.33in',
        rightMargin: '0.33in',
        columnGutter: '0.28125in',
        rowGutter: '0',
        pageWidth: '8.5in',
        pageHeight: '11in',
        labelColumns: 4,
      }],
      labelStyles: {}
    }
  },
  computed: {
    pages() {

      if (this.labelSheet && this.labelSheet.addresses) {
        
        let addresses = this.labelSheet.addresses;
        const labelCount = this.labelFormat.labelsOnPage;
        const pageCount = Math.ceil(addresses.length / labelCount);

        let pages = [];
        for (let i = 0; i < pageCount; i++) {
          pages.push({
            pageNumber: i + 1,
            addresses: this.getAddressesForPage(i)
          });
        }

        return pages;
      }

      return [];
    },
    addresses() {
      return this.labelSheet.addresses;
    },
    labelFormat() {
      const labelFormat = this.labelSheet.label_format;
      if (labelFormat) return this.labelFormatOptions.find(option => option.id === labelFormat);
      return this.labelFormatOptions.find(option => option.id === 'avery-5160');
    },
    labelStyleString() {
      return `width:${this.labelFormat.labelWidth};
              height:${this.labelFormat.labelHeight};
              font-size:${this.labelFormat.fontSize};
              line-height:${this.labelFormat.lineHeight};
              max-height:${this.labelFormat.labelHeight};`
    },
    pageStyleString() {
      return `width:${this.labelFormat.pageWidth};
              height:${this.labelFormat.pageHeight}; 
              padding-top:${this.labelFormat.topMargin};
              padding-bottom:${this.labelFormat.bottomMargin};
              padding-left:${this.labelFormat.leftMargin};
              padding-right:${this.labelFormat.rightMargin};`
    }
  },
  methods: {
    async getLabelSheet () {
      const uuid = this.$route.params.id || null

      if (uuid) {
        const options = {
          method: "GET",
          headers: { 
              "Content-Type": "application/json; charset=utf-8" 
          }
        };
        const url = `/.netlify/functions/fetch-labelsheet?from=label_sheets&uuid=${uuid}`

        let { data, error } = await fetch(url, options).then(res => res.json())

        if(data && data.length) {
          this.labelSheet = data[0]
          if (data[0].label_styles) this.labelStyles = data[0].label_styles
          return
        }
        

        return this.$toast(error.message, {
          type: 'error',
        });
      }

      return this.$toast("Hmmm... that label sheet doesn't seem to exist", {
        type: 'error',
      });
    },
    getAddressesForPage(pageIndex) {
      const start = pageIndex * this.labelFormat.labelsOnPage;
      const end = start + this.labelFormat.labelsOnPage;
      let addresses = this.addresses.slice(start, end);
      const remaining = this.labelFormat.labelsOnPage - addresses.length;

      for (let i = 0; i < remaining ; i++) {
        addresses.push("");
      }
      return addresses;
    }
  },
  mounted() {
    this.getLabelSheet()
  },
  beforeDestroy() {
    this.$toast.clear();
  }
}
</script>

<style>
.paper {
  height: 11in;
  width: 8.5in;
  padding: 0.5in 0.25in;
}

.address {
  font-size: 11pt;
}

.label {
  font-size: 10pt;
  height: 1in;
  line-height: 1.35;
  max-height: 1in;
}
.label:first-child {
  border-top-width: 1px;
}

@media print {
    @page { margin: 0; }
    body * {
      visibility: hidden;
    }
    #printArea, #printArea * {
      visibility: visible;
    }
    .paper {
      height: 100%;
      width: 100%;
      page-break-after: always;
      page-break-inside: avoid;
    }
    .label {
      color: #000;
    }
    .label:first-child {
      border-top-width: 0px;
    }
}
</style>