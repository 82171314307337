<template class="bg-gray-100 min-h-screen">
  <div class="bg-gray-100 block p-8">
    <div class="flex flex-col md:flex-row justify-between items-center header-section mx-auto mb-6 relative">
      <div class="inline-block text-left">
        <Logo />
      </div>
      <div class="mt-4 md:mt-0 hidden md:inline-block">
        <vButton @click="redirectNew()" color="blue" :primary="false">
          Create new label sheet
        </vButton>
      </div>
    </div>
    <div id="hero-container" class="header-section py-2 md:py-12 md:w-3/4 mx-auto max-w-full grid grid-cols-3">
      <div class="text-left col-span-3 md:col-span-2 pr-16">
        <h1 class="text-3xl md:text-2xl md:text-4xl md:leading-snug font-semibold font-display">
        Your labels are ready to download!
        </h1>
        <p class="text-gray-600 mt-2 mb-6">Click the button above to download your print-ready PDF. The file will open in a new tab, which you can save or print directly from your browser.</p>
        <vButton :disabled="isLoading" @click="downloadPDF()" color="green" emoji="⬇" :primary="true" class="mr-6 py-4 px-8">
          Download Labels
        </vButton>
        <div class="text-left w-full mt-12" v-if="!isLoading">
          <div v-if="labelsheet && labelsheet.uuid">
            <h3 class="mb-2 font-display text-xl">Need to make changes?</h3>
            <p class="text-gray-600 my-2">No problem, just go to the edit page to pick up where you left off!</p>
            <router-link :to="`/edit/${labelsheet.uuid}`" class="hover:text-blue-700 underline hover:bg-blue-500 hover:bg-opacity-10 rounded inline-block">Edit Labels</router-link>
          </div>
        </div>
      </div>
      <div v-if="labelsheet && labelsheet.addresses" class="relative w-full text-left hidden md:block">
        <div class="mb-4 mr-8 shadow-xl border border-gray-100 rounded w-64 h-24 text-center flex items-center justify-center text-xs text-gray-600 bg-white z-50 relative py-4 whitespace-pre transform scale-105">
          {{ labelsheet.addresses[0] }}
        </div>
        <div class="mb-4 mr-8 shadow-lg border border-gray-100 rounded w-64 h-24 text-center flex items-center justify-center text-xs text-gray-600 bg-white -mt-8 z-40 relative whitespace-pre transform scale-90 bg-opacity-80">
          {{ labelsheet.addresses[1] }}
        </div>
        <div class="mb-4 mr-8 shadow-lg border border-gray-100 rounded w-64 h-24 text-center flex items-center justify-center text-xs text-gray-600 bg-white -mt-10 z-30 relative whitespace-pre transform scale-75 bg-opacity-60">
          {{ labelsheet.addresses[2] }}
        </div>
        <div class="mb-4 mr-8 shadow-md border border-gray-100 rounded w-64 h-24 text-center flex items-center justify-center text-xs text-gray-600 bg-white -mt-12 z-20 relative whitespace-pre transform scale-65 bg-opacity-40">
          {{ labelsheet.addresses[3] }}
        </div>
        <div class="mb-4 mr-8 shadow-md border border-gray-100 rounded w-64 h-24 text-center flex items-center justify-center text-xs text-gray-600 bg-white -mt-16 z-10 relative whitespace-pre transform scale-50 bg-opacity-20">
          {{ labelsheet.addresses[4] }}
        </div>
      </div>
    </div>

    <!-- <div class="header-section mx-auto grid grid-cols-1 md:grid-cols-3 gap-12">
      <div class="text-gray-500 text-center py-24 w-full md:col-span-3" v-if="isLoading">
        <p class="text-center w-full">Loading...</p>
      </div>
      <div class="px-10 py-8 text-left w-full mx-auto md:col-span-2 bg-white rounded-md" v-if="!isLoading">
        <vButton :disabled="isLoading" @click="downloadPDF()" color="green" emoji="⬇" :primary="true" class="mr-6 py-4 px-8">
          Download Labels
        </vButton>
        <div v-if="labelsheet && labelsheet.uuid" class="mt-10">
          <h3 class="mb-2 font-display text-xl">Need to make changes?</h3>
          <p class="text-gray-600 my-2">No problem, just go to the edit page to pick up where you left off!</p>
          <router-link :to="`/edit/${labelsheet.uuid}`" class="hover:text-blue-700 underline hover:bg-blue-500 hover:bg-opacity-10 rounded inline-block">Edit Labels</router-link>
        </div>
      </div>
      <div v-if="labelsheet && labelsheet.addresses" class="relative w-full text-left pt-8 hidden md:block">
        <div class="mb-4 mr-8 shadow-xl border border-gray-100 rounded w-64 h-24 text-center flex items-center justify-center text-xs text-gray-600 bg-white z-50 relative py-4 whitespace-pre transform scale-105">
          {{ labelsheet.addresses[0] }}
        </div>
        <div class="mb-4 mr-8 shadow-lg border border-gray-100 rounded w-64 h-24 text-center flex items-center justify-center text-xs text-gray-600 bg-white -mt-8 z-40 relative whitespace-pre transform scale-90 bg-opacity-80">
          {{ labelsheet.addresses[1] }}
        </div>
        <div class="mb-4 mr-8 shadow-lg border border-gray-100 rounded w-64 h-24 text-center flex items-center justify-center text-xs text-gray-600 bg-white -mt-10 z-30 relative whitespace-pre transform scale-75 bg-opacity-60">
          {{ labelsheet.addresses[2] }}
        </div>
        <div class="mb-4 mr-8 shadow-md border border-gray-100 rounded w-64 h-24 text-center flex items-center justify-center text-xs text-gray-600 bg-white -mt-12 z-20 relative whitespace-pre transform scale-65 bg-opacity-40">
          {{ labelsheet.addresses[3] }}
        </div>
        <div class="mb-4 mr-8 shadow-md border border-gray-100 rounded w-64 h-24 text-center flex items-center justify-center text-xs text-gray-600 bg-white -mt-16 z-10 relative whitespace-pre transform scale-50 bg-opacity-20">
          {{ labelsheet.addresses[4] }}
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import vButton from "@/components/vButton.vue";
import Logo from "@/components/Logo.vue";

export default {
  name: 'Download',
  components: {
    vButton,
    Logo
  },
  data () {
    return {
      labelsheet: null,
      userEmail: null,
      isLoading: false,
      labelFormat: null
    }
  },
  mounted() {
    this.$toast.clear();
    this.getLabelSheet();
  },
  beforeDestroy() {
    this.isLoading = false;
    this.$toast.clear();
  },
  methods: {
    redirectHome() {
      this.$router.push({ path: '/new' })
    },
    redirectNew() {
      this.$router.push({ path: '/new' })
    },
    async getLabelSheet () {
      this.isLoading = true;
      const uuid = this.$route.params.id || null
      
      if (uuid) {
        const options = {
          method: "GET",
          headers: { 
              "Content-Type": "application/json; charset=utf-8" 
          }
        };
        const url = `/.netlify/functions/fetch-labelsheet?from=label_sheets&uuid=${uuid}`

        let { data, error } = await fetch(url, options).then(res => res.json())

        if(data && data.length) {
          this.addresses = data[0].addresses;
          if (data[0].user_email) this.userEmail = data[0].user_email
          if (data[0].label_format) this.labelFormat = data[0].label_format
          if (data[0]) this.labelsheet = data[0]
          return this.isLoading = false;
        }

        if(error) this.$toast(error.message, { type: 'error' });
        this.isLoading = false;
        return this.$toast("Hmmm... we're having trouble finding that label sheet", { type: 'error' });
      }

      this.isLoading = false;
      return this.$toast("Hmmm... that label sheet doesn't seem to exist", {
        type: 'error',
      });
    },
    async downloadPDF () {
      window.fathom.trackGoal('FIHKJO98', 0);
      const uuid = this.$route.params.id || null;
      const filename = `label-sheet-${uuid}.pdf`;

      if (uuid) {
        const options = {
          method: "GET",
          headers: { 
              "Content-Type": "application/json; charset=utf-8" 
          }
        };
        const url = `/.netlify/functions/pdf-file?filename=${filename}`

        let { data, error } = await fetch(url, options).then(res => res.json())

        if (data.signedURL) return window.open(data.signedURL, '_blank');
        if (error) return this.$toast(error.message, { type: 'error' });
      }
    },
  }
}
</script>