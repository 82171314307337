<template>
  <div class="block p-8 text-center">
    <div class="flex flex-col md:flex-row justify-between items-center header-section mx-auto mb-4 relative">
      <div class="inline-block text-left">
        <Logo />
      </div>
      <p class="block md:hidden bg-gray-200 px-3 py-2 mx-auto header-section rounded mt-4 text-xs font-semibold text-gray-700">
        Note: Editing experience is designed for laptop or desktop screens and may be difficult on phones or tablets.
      </p>
      <div class="mt-4 md:mt-0">
        <!-- <vButton @click="clearAddresses" color="red" emoji="🗑️">
            Clear
        </vButton> -->
        <vButton :disabled="isLoading" @click="saveAndRedirect()" color="blue" emoji="💾" class="ml-2">
            Save
        </vButton>
        <vButton :disabled="isLoading" @click="showEmailModal = true" :primary="true" color="green" emoji="🖨" class="ml-2">
            Download
        </vButton>
      </div>
    </div>
    <LabelSheet :addresses="addresses"
                :labelFormat="labelFormat"
                :labelStyles="labelStyles"
                @updateLabelStyles="updateLabelStyles"
                @updateLabelFormat="updateLabelFormat"></LabelSheet>
    <Modal v-if="showEmailModal">
      <h1 class="text-xl font-semibold mb-2">Download your PDF</h1>
      <p class="text-gray-600">Ready to print your labels? Enter your email to receive your print-ready PDF file!</p>
      <div v-if="emailSuccess" class="mt-4 py-4 px-6 bg-green-500 bg-opacity-10 border border-green-500 text-green-800 rounded">
        <h2 class="font-semibold">Labels on the way!</h2>
        <p>We've sent you an email at <span v-if="userEmail" class="font-bold mb-4">{{ userEmail }}</span> with a direct link to your printable PDF file.</p>
        <vButton :disabled="isLoading" @click="resetModal" color="blue" class="inline-block mt-3">
          Continue editing
        </vButton>
      </div>
      <form v-else class="mt-4">
        <label class="text-sm font-semibold text-gray-500" for="user-email">Email address</label>
        <input class="border border-gray-300 py-2 px-3 w-full text-left" 
              type="email"
              name="user-email"
              v-model="userEmail">
        <div class="mt-4">
          <vButton :disabled="isLoading" @click="saveAndSendLink()" :primary="true" color="green" class="mr-2">
            Send PDF
          </vButton>
          <vButton :disabled="isLoading" @click="showEmailModal = false" color="red">
            Cancel
          </vButton>
        </div>
      </form>
      <div v-if="saveStatus" class="mt-4 py-4 px-6 bg-gray-100 text-gray-500 rounded">
        <span class="animate-bounce inline-block w-2 h-2 bg-gray-400 rounded-full mr-3"></span>
        <span>{{ saveStatus }}</span>
      </div>
    </Modal>
  </div>
</template>

<script>
import vButton from "@/components/vButton.vue";
import LabelSheet from "@/components/LabelSheet.vue";
import Logo from "@/components/Logo.vue";
import Modal from "@/components/Modal.vue";

export default {
  name: 'New',
  components: {
    vButton,
    LabelSheet,
    Logo,
    Modal,
  },
  data () {
    return {
      addresses: [],
      labelsOnPage: 30,
      pageCount: 0,
      userEmail: null,
      showEmailModal: false,
      emailSuccess: false,
      isLoading: false,
      saveStatus: null,
      labelFormat: null,
      labelStyles: {}
    }
  },
  computed: {
    pages() {
      let pages = [];
      for (let i = 0; i < this.pageCount; i++) {
        pages.push({
          pageNumber: i + 1,
        });
      }
      return pages;
    }
  },
  methods: {
    resetModal() {
      this.emailSuccess = false
      this.isLoading = false
      this.showEmailModal = false
      this.saveStatus = null
    },
    getAddressesForPage(pageIndex) {
      const start = pageIndex * this.labelsOnPage;
      const end = start + this.labelsOnPage;
      const addresses = this.addresses.map((a, index) => {
        return {
          address: a,
          addressIndex: index,
        }
      }).slice(start, end);
      return addresses;
    },
    updateLabelFormat(format) {
      this.labelFormat = format;
    },
    updateLabelStyles(style, value) {
      this.labelStyles[style] = value;
    },
    async saveAndSendLink() {
      window.fathom.trackGoal('7KTPVGG0', 0);
      this.emailSuccess = false;
      this.isLoading = true;
      this.saveStatus = "LabelBot is generating your PDF..."
      const labelSheet = await this.saveLabelSheet()
      if (!labelSheet || !labelSheet.pdfFile) {
        this.isLoading = false;
        this.saveStatus = null;
        return this.$toast('Error! Please try again.', { type: 'error' })
      } 

      this.saveStatus = "Preparing for email delivery..."

      const filename = labelSheet.pdfFile
      const uuid = labelSheet.uuid

      return await this.sendEmailLink(uuid, filename).then(() => {
        this.isLoading = false;
        this.emailSuccess = true;
        this.saveStatus = null;
        return this.$toast("Check your email for a link to your PDF file!", {type: 'success'})
      }).catch(err => {
        this.saveStatus = null;
        return this.$toast(err, {type: 'error'})
      })
    },
    async saveAndRedirect () {
      window.fathom.trackGoal('GAO6IKYJ', 0);
      this.isLoading = true;
      const labelSheet = await this.saveLabelSheet()
      if (!labelSheet || !labelSheet.uuid) {
        this.isLoading = false;
        return this.$toast('Error! Please try again.', { type: 'error' })
      }

      this.$router.push({ path: `/edit/${labelSheet.uuid}` })
    },
    async saveLabelSheet () {
      const addresses = this.addresses || []
      const user_email = this.userEmail || null
      const label_format = this.labelFormat || 'avery-5160'
      const label_styles = this.labelStyles || null;
      const body = { addresses, user_email, label_format, label_styles }

      const options = {
        method: "POST",
        headers: { 
            "Content-Type": "application/json; charset=utf-8" 
        },
        body: JSON.stringify(body)
      };

      const { data, error } = await fetch("/.netlify/functions/create-labelsheet", options).then(res => res.json())
      .catch(() => {
        this.isLoading = false;
        this.$toast('Error! Please try again.', {type: 'error'})
      })
      if (data) return data[0]
      if (error) this.$toast('Error! Please try again.', {type: 'error'})
    },
    async sendEmailLink(uuid, filename) {
      if (this.userEmail) {
        const email = this.userEmail

        const body = { email, uuid, filename }
        const options = {
          method: "POST",
          headers: { 
              "Content-Type": "application/json; charset=utf-8" 
          },
          body: JSON.stringify(body)
        };

        let { data, error } = await fetch("/.netlify/functions/send-email-link", options).then(res => res.json())
          .catch(() => {
            this.isLoading = false;
            this.saveStatus = null;
            this.$toast('Error! Please try again.', {type: 'error'})
          })
      
        if (data) return data;
        if (error) {
          this.isLoading = false;
          this.saveStatus = null;
          this.$toast(error, {type: 'error'})
        }
      }
    },
  },
  mounted() {
    this.$toast.clear();
  },
  beforeDestroy() {
    this.isLoading = false;
    this.$toast.clear();
  }
}
</script>
