<template>
  <div class="block p-8">
    <div class="flex justify-between items-center header-section-home mx-auto mb-6 relative">
      <div class="inline-block text-left">
        <Logo />
      </div>
      <div>
        <vButton @click="redirectNew()" color="blue" :primary="true">
            Create labels
        </vButton>
      </div>
    </div>

    <div id="hero-container" class="container py-10 md:py-16 md:w-3/4 mx-auto max-w-full text-center">
      <h1 class="text-3xl md:text-6xl md:leading-snug font-semibold font-display">
        The easiest way to create & print mailing labels
      </h1>
      <p class="mt-4 text-md md:text-xl mb-8 block sm:w-3/4 md:w-1/2 mx-auto text-gray-600">Add your addresses, choose your template and get a <strong class="text-gray-800 border-b-4 border-yellow-500 inline-block">free print-ready PDF</strong> sent straight to your email</p>
      <vButton @click="redirectNew()" color="blue" :primary="true">
        Get started FREE
      </vButton>
    </div>

    <div class="p-12 text-left md:w-2/3 lg:w-1/2 mx-auto bg-white rounded-md">
      <h2 class="font-display text-2xl">How does it work?</h2>
      <p class="text-gray-600 mt-2">Instead of fighting with Microsoft Word/Excel or Google Docs, Labeltime is an easy tool to create, download and print your mailing address labels.</p>
      <ol class="mt-2 leading-relaxed text-gray-600">
        <li>1. Choose a template (we currently support the most popular Avery label templates)</li>
        <li>2. Type your addresses in the easy-to-use editor</li>
        <li>3. Click "Download" and enter your email</li>
        <li>4. Receive a print-ready PDF file straight to your email!</li>
      </ol>
      
      <h3 class="mt-4 font-display text-lg">Is it really free?</h3>
      <p class="text-gray-600 mt-1">Yep, Labeltime is 100% free! This is a one-person project run by me, <a href="https://twitter.com/austinwprice" target="_blank" class="hover:text-blue-700 underline hover:bg-blue-500 hover:bg-opacity-10 rounded">Austin</a>. Right now, I'm working on it just for fun but I may add premium features in the future to help support the project.</p>
      
      <h3 class="mt-4 font-display text-lg">Which label templates do you support?</h3>
      <p class="text-gray-600 mt-1">Right now, we support the most popular Avery label templates (Avery 5160 and Avery 5167) but will be adding more in the future.</p>

      <h3 class="mt-4 font-display text-lg">What if I need a format that isn't supported?</h3>
      <p class="text-gray-600 mt-1">Email me at <a href="mailto:support@label-time.com" class="hover:text-blue-700 underline hover:bg-blue-500 hover:bg-opacity-10 rounded">support@label-time.com</a> and I'll add it for you! I'm working to continually add more label formats.</p>
    </div>
  </div>
</template>

<script>
import vButton from "@/components/vButton.vue";
import Logo from "@/components/Logo.vue";

export default {
  name: 'Home',
  components: {
    vButton,
    Logo
  },
  methods: {
    redirectNew() {
      this.$router.push({ path: '/new' })
    },
  }
}
</script>

<style scoped>
@media (min-width: 1024px) {
  .header-section-home {
    width: 8.5in;
  }
}
</style>