<template>
    <button 
        v-if="primary"
        :disabled="disabled"
        @click="$emit('click')"
        class="rounded px-5 py-3 font-semibold text-white text-md tracking-wide	 ease-out cursor-pointer
            transition hover:underline focus:outline-black disabled:cursor-wait"
        :class="[disabled ?  `bg-${color}-300 text-${color}-800 cursor-wait hover:no-underline` : `bg-${color}-600 hover:bg-${color}-500 cursor-pointer`]">
        <span  v-if="emoji" class="no-underline inline-block mr-2">{{ emoji }}</span>
        <label :class="[ disabled ? 'cursor-wait' : 'cursor-pointer' ]">
            <slot></slot>
        </label>
    </button>
    <button 
        v-else
        :disabled="disabled"
        @click="$emit('click')"
        class="rounded px-5 py-3 font-semibold text-md tracking-wide ease-out cursor-pointer
            transition hover:bg-opacity-10 hover:underline focus:outline-black disabled:cursor-wait"
        :class="[disabled ? `hover:bg-opacity-100 hover:no-underline cursor-wait bg-gray-200 text-gray-500` : 
        `hover:bg-${color}-700 hover:text-${color}-700 cursor-pointer bg-gray-200 text-gray-800`]">
        <span  v-if="emoji" class="no-underline inline-block mr-2">{{ emoji }}</span>
        <label :class="[ disabled ? 'cursor-wait' : 'cursor-pointer' ]">
            <slot></slot>
        </label>
    </button>
</template>

<script>
export default {
  name: 'vButton',
  props: {
      color: {
        type: String,
        default: 'blue',
      },
      primary: {
        type: Boolean,
        default: false
      },
      emoji: String,
      disabled: {
          type: Boolean,
          default: false
      }
  },
}
</script>

