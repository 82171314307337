<template>
  <div class="text-left">
    <div v-if="!printStyles" class="mb-4 bg-gray-100 rounded-md flex flex-col md:flex-row header-section justify-between items-stretch mx-auto p-1">
      <div class="inline-flex items-center text-left mr-8">
        <label class="uppercase font-semibold text-xs text-gray-700 pr-2">Format:</label>
        <vSelect  :options="labelFormatOptions"
                    @input="setLabelFormat"
                    :clearable="false"
                    class="cursor-pointer w-40 p-1 bg-white rounded font-semibold hover:bg-white shadow-sm transition ease-in hover:shadow border border-gray-200"
                    :searchable="false"
                    placeholder="Select label sheet format"
                    :value="labelFormat"></vSelect>
        <!-- <div class="inline-block pl-3 text-xs text-gray-500">
          <p>{{ labelFormat.labelWidth }} x {{ labelFormat.labelHeight }}</p>
          <p>{{ labelFormat.labelsOnPage }} labels per page</p>
        </div> -->
      </div>
      <div class="text-left flex flex-row items-center">
              <label class="uppercase font-semibold text-xs text-gray-700 pr-2">Font:</label>
              <vSelect  :options="fontFamilyOptions"
                        @input="setFontStyle"
                        :clearable="false"
                        class="cursor-pointer w-56 p-1 mr-2 bg-white rounded font-semibold hover:bg-white shadow-sm transition ease-in hover:shadow"
                        :class="`font-${fontFamilyStyle}`"
                        :searchable="false"
                        placeholder="Select font"
                        :value="fontFamily">
                <template #selected-option="{ label }">
                  <div :class="`font-${fontFamilyStyle}`">{{ label }}</div>
                </template>
                <template #option="{ label, font }">
                  <div :class="`font-${font}`">{{ label }}</div>
                </template>
              </vSelect>
              <vSelect  :options="fontSizeOptions"
                        @input="setFontSize"
                        :clearable="false"
                        class="cursor-pointer w-24 p-1 mr-2 bg-white rounded font-semibold hover:bg-white shadow-sm transition ease-in hover:shadow"
                        :searchable="false"
                        placeholder="Select font"
                        :value="fontSize"></vSelect>
            <v-swatches v-model="textColor"
                        @input="setTextColor"
                        :swatches='colorOptions'
                        class="pt-1"
                        :trigger-style="{ width: '33px', height: '33px' }"></v-swatches>
      </div>
    </div>
  
    <div  v-for="(page, pageIndex) in pages" :key="page.pageNumber"
          :style="pageStyleString"
          class="bg-white paper mb-8 drop-shadow mx-auto relative print:mb-0 print:visible">
      <div  class="absolute top-2 -left-14"
            :class="[printStyles ? 'print:hidden' : '']">
        <label class="uppercase text-xs text-gray-400">Page {{ page.pageNumber }}</label>
      </div>
      <div  class="grid grid-cols-3 h-full content-start"
            :class="`grid-cols-${labelFormat.labelColumns}`"
            :style="`column-gap:${labelFormat.columnGutter}; row-gap:${labelFormat.rowGutter};`">
        <div 
          v-for="(address, index) in getAddressesForPage(pageIndex)"
          :key="`label-${index}`"
          class="text-center border border-gray-200 label content-border flex items-center content-center relative overflow-hidden"
          :class="[printStyles ? 'border-none print:border-none' : '']"
          :style="labelStyleString">
          <contenteditable class="whitespace-pre w-full outline-none overflow-visible leading-tight z-10"
            :class="`font-${fontFamilyStyle}`"
            :style="`font-size: ${fontSize}px; color: ${textColor}`"
            tag="div" :contenteditable="true" v-model="addresses[address.addressIndex]" :noNL="false">
          </contenteditable>
          <div
            v-if="addresses[address.addressIndex] < 1"
            :class="[printStyles ? 'hidden print:hidden' : '', `font-${fontFamilyStyle}`]"
            class="absolute text-gray-300 text-xs w-full text-center">
            Type address...
          </div>
        </div>
      </div>
    </div>
    <vButton  @click="addPage()" 
              color="blue"
              emoji="➕"
              :class="[printStyles ? 'print:hidden' : '']"
              class="mx-auto block">
      Add Page
    </vButton>
  </div>
</template>

<script>
import vButton from "@/components/vButton.vue";
import vSelect from "vue-select";
import VSwatches from 'vue-swatches';
import "vue-swatches/dist/vue-swatches.css";

export default {
  name: 'LabelSheet',
  props: {
    addresses: Array,
    labelFormatID: String,
    printStyles: {
      type: Boolean,
      default: false
    },
    labelStyles: Object
  },
  components: {
    vButton,
    vSelect,
    VSwatches
  },
  data() {
    return {
      pageCount: 0,
      labelFormat: {},
      labelFormatOptions: [{
        label: 'Avery 5160',
        id: 'avery-5160',
        labelsOnPage: 30,
        labelWidth: '2.625in',
        labelHeight: '1in',
        fontSize: '13px',
        lineHeight: '18px',
        fontWeight: 'regular',
        topMargin: '0.5in',
        bottomMargin: '0.5in',
        leftMargin: '0.21975in',
        rightMargin: '0.21975in',
        columnGutter: '0.14in',
        rowGutter: '0',
        pageWidth: '8.5in',
        pageHeight: '11in',
        labelColumns: 3,
      }, {
        label: 'Avery 5167',
        id: 'avery-5167',
        labelsOnPage: 80,
        labelWidth: '1.75in',
        labelHeight: '0.5in',
        fontSize: '12px',
        lineHeight: '14px',
        fontWeight: 'regular',
        topMargin: '0.5in',
        bottomMargin: '0.5in',
        leftMargin: '0.33in',
        rightMargin: '0.33in',
        columnGutter: '0.28125in',
        rowGutter: '0',
        pageWidth: '8.5in',
        pageHeight: '11in',
        labelColumns: 4,
      }],
      fontFamily: {
        label: 'Open Sans',
        id: 'sans-serif',
        font: 'sans-serif'
      },
      fontFamilyOptions: [{
        label: 'Open Sans',
        id: 'open-sans',
        font: 'sans-serif'
      }, {
        label: "Dancing Script",
        id: 'dancing',
        font: 'dancing'
      }, {
        label: "Fredoka One",
        id: 'fredoka',
        font: 'fredoka'
      }, {
        label: "Merriweather",
        id: 'merriweather',
        font: 'display'
      }, {
        label: "Petit Formal Script",
        id: 'petit',
        font: 'petit'
      }, {
        label: "Playfair Display",
        id: 'playfiar',
        font: 'playfair'
      }, {
        label: "Zilla Slab",
        id: 'zilla',
        font: 'zilla'
      }],
      fontSizeOptions: [8,10,12,14,16,20,24,28,32,40],
      fontSize: 14,
      textColor: '#000000',
      colorOptions: [
        '#000000',
        '#EC4899',
        '#DC2626',
        '#4F46E5',
        '#2563EB',
        '#059669',
        '#FBBF24',
        '#D97706'
      ]
    }
  },
  computed: {
    pages() {
      let pages = [];
      for (let i = 0; i < this.pageCount; i++) {
        pages.push({
          pageNumber: i + 1,
        });
      }
      return pages;
    },
    labelCount() {
      return this.labelFormat.labelsOnPage * this.pageCount;
    },
    labelStyleString() {
      return `width:${this.labelFormat.labelWidth};
              height:${this.labelFormat.labelHeight};
              font-size:${this.labelFormat.fontSize};
              line-height:${this.labelFormat.lineHeight};
              max-height:${this.labelFormat.labelHeight};`
    },
    pageStyleString() {
      return `width:${this.labelFormat.pageWidth};
              height:${this.labelFormat.pageHeight}; 
              padding-top:${this.labelFormat.topMargin};
              padding-bottom:${this.labelFormat.bottomMargin};
              padding-left:${this.labelFormat.leftMargin};
              padding-right:${this.labelFormat.rightMargin};`
    },
    fontFamilyStyle() {
      return this.fontFamily.font;
    }
  },
  mounted() {
    let labelFormat = this.labelFormatOptions[0]
    if (this.labelFormatID) {
      labelFormat = this.labelFormatOptions.find(option => option.id === this.labelFormatID)
    }
    let fontFamily = this.fontFamilyOptions[0]
    if (this.labelStyles.fontFamily) {
      fontFamily = this.fontFamilyOptions.find(option => option.id === this.labelStyles.fontFamily)
    }

    let fontSize = 14
    if (this.labelStyles.fontSize) fontSize = this.labelStyles.fontSize

    let textColor = "#000000"
    if (this.labelStyles.textColor) textColor = this.labelStyles.textColor
          
    // styles.forEach((style) => {
    //   if (this.labelStyles[style]) {
    //     const styleID = this.labelStyles[style]
    //     const styleObject = this.fontFamilyOptions.filter(f => f.id === styleID)
    //     this[style] = styleObject
    //   }
    // })

    this.fontFamily = fontFamily
    this.fontSize = fontSize
    this.textColor = textColor
    this.labelFormat = labelFormat
    this.addRemainingPages()
  },
  methods: {
    setLabelStyles() {
      const styles = [
        'fontFamily'
      ]
      
      styles.forEach((style) => {
        const styleID = this.labelStyles[style]
        this[style] = this.fontFamilyOptions[styleID]
      })
    },
    addPage() {
      this.pageCount ++;
      this.addRemainingAddressSpaces();
    },
    getAddressesForPage(pageIndex) {
      const labelsOnPage = this.labelFormat.labelsOnPage;
      const start = pageIndex * labelsOnPage;
      let addresses = [];
      for (let i = 0; i < labelsOnPage; i++) {
        addresses.push({
          addressIndex: i + start
        })
      }
      return addresses;
    },
    addRemainingPages() {
      const labelCount = this.labelFormat.labelsOnPage * this.pageCount;
      const addressCount = this.addresses.length;
      let pagesToAdd = Math.ceil((addressCount - labelCount) / this.labelFormat.labelsOnPage);
      if (this.pageCount < 1 && pagesToAdd < 1) pagesToAdd = 1;

      for (let i = 0; i < pagesToAdd; i++) {
        this.addPage();
      }
    },
    addRemainingAddressSpaces() {
      const addressCount = this.addresses.length;
      const labelsOnPage = this.labelFormat.labelsOnPage * this.pageCount;
      let spacesToFill = labelsOnPage - addressCount;
      if (addressCount < 1 || isNaN(spacesToFill)) spacesToFill = labelsOnPage;
      for (let i = 0; i < spacesToFill; i++) {
        this.addresses.push("");
      }
    },
    trimAddresses(labelsOnPage) {
      const labelCount = labelsOnPage || this.labelFormat.labelsOnPage;
      let lastPageWithValue = 1;
      this.addresses.forEach((address, index) => {
        if (address.length > 1 && index > 0) {
          lastPageWithValue = Math.ceil(index / labelCount)
        }
      })
      if (lastPageWithValue && this.pageCount > 1) {
        const pagesToRemove = this.pageCount - lastPageWithValue;
        this.pageCount = this.pageCount - pagesToRemove;
        const end = this.pageCount * labelCount;
        const newAddresses = this.addresses.slice(0, end);
        this.$emit('updateAddresses', newAddresses)
      }
    },
    setLabelFormat(format) {
      window.fathom.trackGoal('5OA2OBLN', 0);
      this.pageCount = 0;
      this.labelFormat = format;
      this.$emit('updateLabelFormat', format.id);
      this.addRemainingPages();
      this.trimAddresses();
    },
    setFontStyle(value) {
      this.fontFamily = value;
      this.$emit('updateLabelStyles', 'fontFamily', value.id);
    },
    setFontSize(value) {
      this.fontSize = value;
      this.$emit('updateLabelStyles', 'fontSize', value);
    },
    setTextColor(value) {
      this.textColor = value;
      this.$emit('updateLabelStyles', 'textColor', value);
    }
  }
}
</script>

<style>
  .address {
    font-size: 11pt;
  }
  .label {
    font-size: 10pt;
    line-height: 1.35;
  }
  .label:first-child {
    border-top-width: 1px;
  }

  .vs__selected {
    font-size: 0.85rem;
    font-weight: 600;
  }
  .vs__dropdown-toggle {
    border: none !important;
  }
  .vs--open .vs__selected {
    opacity: 1 !important;
  }
</style>