import Vue from 'vue'
import VueRouter from 'vue-router'
import Download from '../views/Download.vue'
import Editor from '../views/Editor.vue'
import Home from '../views/Home.vue'
import New from '../views/New.vue'
import GeneratePDF from '../views/GeneratePDF.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, {
    path: '/new',
    name: 'New',
    component: New
  }, {
    path: '/edit/:id',
    name: 'Editor',
    component: Editor
  }, {
    path: '/generate-pdf/:id',
    name: 'GeneratePDF',
    component: GeneratePDF
  }, {
    path: '/download/:id',
    name: 'Download',
    component: Download
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router
